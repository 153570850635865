var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investors-inner-table"
  }, [_c('b-table', _vm._g(_vm._b({
    ref: "innerTable",
    staticClass: "sygni-b-inner-table",
    attrs: {
      "innertable": "true",
      "fields": _vm.innerTableFieldsComputed
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "head(selected)",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": ""
          },
          on: {
            "input": _vm.selectAllProducts
          },
          model: {
            value: _vm.selectAllProductsOption,
            callback: function callback($$v) {
              _vm.selectAllProductsOption = $$v;
            },
            expression: "selectAllProductsOption"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_vm.canBeAnnexed(rowData.item) ? _c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.selectProduct(rowData.item.id, rowData);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "label": "",
            "value": _vm.isSelected(rowData.item.id)
          }
        })], 1) : _vm._e()];
      }
    }, {
      key: "cell(code)",
      fn: function fn(rowData) {
        return [_c('a', {
          staticClass: "code",
          attrs: {
            "href": _vm.getProductSummaryLink(rowData.item)
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.goToProductSummary(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s(rowData.item.code ? rowData.item.code : 'NONE') + " ")])];
      }
    }, {
      key: "cell(sourceName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "source-name"
        }, [_vm._v(" " + _vm._s(rowData.item.sourceName) + " ")])];
      }
    }, {
      key: "cell(ownerName)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "owner-name"
        }, [_vm._v(" " + _vm._s(rowData.item.ownerName) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(rowData) {
        return [rowData.item.type.toUpperCase() === 'LLC_SHARES' ? [_vm._v(" LLC Shares ")] : _vm._e(), rowData.item.type.toUpperCase() === 'JOINT_STOCK_SHARES' ? [_vm._v(" Joint-stock Shares ")] : _vm._e(), rowData.item.type.toUpperCase() !== 'LLC_SHARES' && rowData.item.type.toUpperCase() !== 'JOINT_STOCK_SHARES' ? [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.type)) + " ")] : _vm._e()];
      }
    }, {
      key: "cell(agreementDate)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("dateWithoutTime")(rowData.item.agreementDate)) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "outline round ".concat(_vm.statusClass(rowData.item.status)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm.statusText(rowData.item.status)) + " ")])], 1)];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        var _rowData$item, _rowData$item$value;

        return [(_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && (_rowData$item$value = _rowData$item.value) !== null && _rowData$item$value !== void 0 && _rowData$item$value.value ? _c('span', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value.value, 2)) + " " + _vm._s(rowData.item.value.currency) + " ")]) : _vm._e()];
      }
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedProducts.length > 0,
      expression: "selectedProducts.length > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": false
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedProducts.length))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline disabled",
    on: {
      "click": _vm.clearTableSelection
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    class: ['dropdown']
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [_vm.selectedProducts.length === 1 ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.createAnnex('annex');
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Annex")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('BOOK'),
      "alt": ""
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.createAnnex('rollover');
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Roll")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('BOOK'),
      "alt": ""
    }
  })])])])])], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }